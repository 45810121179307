
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import dateTimeHelper from '../../helper/datetime.helper';
import { ApiService } from '../../services/backend-api/api.services';
import { default as PostModel } from '../../models/post.model';

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    let loading = ref(false);
    let errors = ref<string[]>([]);
    let post = ref<any>({
      title: '',
      category_id: '',
      published_at: 0,
      pin: false,
      show: false,
      landing_url: '',
      contents_url: '',
      desc: '',
      thumbnail: '',
      created_at: 0,
      updated_at: 0,
      view_count: 0,
    });
    let postId = router.currentRoute.value.params.id;
    onMounted(async () => {
      const apiService = new ApiService();
      let previewData = await apiService.previewPost(postId);
      if (previewData.status) {
        post.value = previewData.data;

        //update view_count field
        await apiService.updatePostViewCount(postId);
      } else {
        errors.value.push(previewData.data.message);
      }
      loading.value = false;

      setTimeout(() => {
        const height = document.body.scrollHeight;
        window.parent.postMessage({ height: height }, '*');
      }, 500);

      handleLinks();

      function handleLinks() {
        document.querySelectorAll('a').forEach(el => {
          el.addEventListener('click', e => {
            const href = el.getAttribute('href');

            // 특정 링크 확인
            if (checkPcLoaded()) {
              e.preventDefault(); // 링크 기본 동작 막기
              window.parent.postMessage({ url: href }, '*');
            }
          });
        });
      }

      function checkPcLoaded() {
        const userAgent = navigator.userAgent || navigator.vendor;

        // 모바일 디바이스 체크
        if (/android/i.test(userAgent)) {
          return false;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return false;
        }

        return true;
      }
    });

    return {
      errors,
      post,
      loading,
      dateTimeHelper,
    };
  },
});
